<script lang="ts">
  import * as Accordion from '@components/ui/accordion';
  import { Typography } from '@components/ui/typography';

  import type { FaqSection } from '.';

  type $$Props = FaqSection;

  let className: $$Props['class'] = '';
  // TODO: type
  // eslint-disable-next-line
  export { className as class };
  export let data: $$Props;
</script>

<section class="bg-[--bg-faq-color] py-32 2xl:py-64">
  <div class="container grid grid-cols-1 gap-16">
    <Typography variant="h2" size="6xl" class="text-[--text-faq-color] text-center">
      {data.title}
    </Typography>

    <div class="w-full grid grid-cols-1 gap-3 max-w-[946px] mx-auto">
      {#each data.items as { title, content }}
        <Accordion.Root class="w-full space-y-2 px-6 py-2 rounded-2xl bg-[--card-faq-color]">
          <Accordion.Item value={title}>
            <Accordion.Trigger class="text-[--text-faq-color]">
              <Typography
                variant="h4"
                size="xl"
                class="text-[--text-faq-color] font-light text-left"
              >
                {title}
              </Typography>
            </Accordion.Trigger>
            <Accordion.Content>
              <Typography variant="span" size="sm" class="text-[--text-faq-color]">
                {content}
              </Typography>
            </Accordion.Content>
          </Accordion.Item>
        </Accordion.Root>
      {/each}
    </div>
  </div>
</section>

<style global lang="postcss">
  :root {
    --bg-faq-color: hsla(0, 0%, 13%, 1);
    --card-faq-color: hsla(173, 72%, 18%, 1);
    --text-faq-color: hsl(120, 46%, 87%, 1);
  }
</style>
